@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css");
* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "pretendard";
  font-weight: 400;
  color: #f9f9fb;
}
b {
  font-weight: bold;
}

.App {
  background-color: #161616;
  min-height: 100vh;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  height: auto;
  max-width: 100%;
}
button {
  background: none;
  color: inherit;
  cursor: pointer;
}

.flex_center {
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}
a {
  text-decoration: none;
}
.pc_max_width {
  max-width: 1100px;
}
.language_ul {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: red;
  right: 0px;
  border-radius: 8px;
  background: linear-gradient(0deg, rgb(29, 29, 29) 0%, rgba(73, 73, 73) 100%);
  width: 100px;
  text-align: center;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  z-index: 999;
}
.language_line {
  width: 100%;
  height: 1px;
  background: rgb(228, 220, 220);
}
.language_li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 17px 0px;
  cursor: pointer;
}
.language_li:nth-of-type(1) {
  border-bottom: 1px solid #555;
}
.modal_on {
  max-height: 101px;
}
.modal_off {
  max-height: 0px;
}
